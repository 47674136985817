import { Overview } from 'components/Overview'
import { AmortizationScheduleContent, DocumentsSlideNameType } from 'pages/Loan'
import { useEffect, useMemo, useState } from 'react'
import { getLoanSubmissionDocuments, getPostClosingFields, updatePostClosingFields } from 'services'
import { svgLoading } from 'stories/assets'
import { setLoanNumber } from 'utils/setLoanNumber'

import { Allonge } from './Allonge'
import { Assignment } from './Assignment'
import { AssignmentPdfSettingsModal } from './Assignment/assignmentPdfSettingsModal'
import { DownloadDocuments } from './DownloadDocuments'
import { PurchaseAdvice } from './PurchaseAdvice'
import { PurchaseAdviceSeller } from './PurchaseAdviceSeller'
import { ServicingTransfer } from './ServicingTransfer'
import { ShippingInformation } from './ShippingInformation'
import { TransferLetter } from './TransferLetter'

type menuType = {
  [key: string]: string //fix this
}

const leftMenuItems: menuType = {
  general: 'General',
  allonge: 'Allonge',
  assignment: 'Assignment',
  transferLetter: 'Transfer Letter',
  servicingTransfer: 'Servicing Transfer',
  purchaseAdviceBuyer: 'Purchase Advice Buyer',
  purchaseAdviceSeller: 'Purchase Advice Seller',
  download: 'Cross River Assignment Docs',
  shipping: 'Shipping Information',
  amortization: 'Amortization Schedule',
}

export * from './PurchaseAdvice'
export * from './PurchaseAdviceSeller'

export function PostClosing() {
  const [loading, setLoading] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState('')
  const [assignmentNames, setAssignmentNames] = useState<Array<string>>([])
  const [documents, setDocuments] = useState<Array<any>>([])

  const setMenu = (menuItem: string) => {
    setSelectedMenu(menuItem)
  }

  const renderFragment = useMemo(() => {
    switch (selectedMenu) {
      case 'allonge':
        return <Allonge setLoading={setLoading} />
      case 'assignment':
        return <Assignment setLoading={setLoading} assignmentNames={assignmentNames} />
      case 'transferLetter':
        return <TransferLetter setLoading={setLoading} />
      case 'servicingTransfer':
        return <ServicingTransfer setLoading={setLoading} />
      case 'purchaseAdviceBuyer':
        return <PurchaseAdvice />
      case 'purchaseAdviceSeller':
        return <PurchaseAdviceSeller />
      case 'download':
        return <DownloadDocuments />
      case 'shipping':
        return <ShippingInformation />
      case 'amortization':
        return <AmortizationScheduleContent />
      default: {
        return <div>Comming Soon...</div>
      }
    }
  }, [selectedMenu, assignmentNames])

  useEffect(() => {
    setMenu(Object.keys(leftMenuItems)[0])
    setLoanNumber()
    getPostClosingFields('assignmentNames').then((data) => setAssignmentNames(data))
    getLoanSubmissionDocuments().then((res) => {
      if (res.success) setDocuments(res.data)
    })
  }, [])

  const onUpdateAssignmentNames = (newValues: Array<string>) => {
    setAssignmentNames(newValues)
    updatePostClosingFields('assignmentNames', { assignmentNames: newValues })
  }

  const renderMenu = (menus: Record<string, string>) => {
    return (
      <ul className="sidebar-items flex flex-col p-4 pb-10">
        {Object.keys(menus).map((item: string, index) => {
          const isActive = selectedMenu === item
          return (
            <li
              key={index}
              onClick={() => {
                setMenu(item)
              }}
              className="py-2"
            >
              <p className={`hover:underline cursor-pointer ${isActive ? 'border px-4 py-1 bg-zinc-100' : 'py-1'}`}>
                {index + 1}. {menus[item as keyof typeof menus]}
              </p>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="PostClosing-container py-6 px-2">
      <Overview title="Post Closing" />
      <DocumentsSlideNameType documents={documents} loading={false} />
      <div className="max-w-screen-2xl m-auto grid grid-cols-12 md:gap-6">
        <div className="sidebar-left col-span-12 md:col-span-3 shrink-0 bg-white shadow1 rounded mb-4">
          {renderMenu(leftMenuItems)}
        </div>
        <div className="relative h-fit content-right col-span-12 md:col-span-9 bg-white p-4 rounded shadow1 mb-4">
          <h2 className="text-2xl font-bold flex items-center mb-3">
            {leftMenuItems[selectedMenu]}
            {selectedMenu == 'assignment' && (
              <AssignmentPdfSettingsModal
                assignmentNames={assignmentNames}
                onOk={(values) => onUpdateAssignmentNames(values)}
              />
            )}
            <span className="text-base ml-3">
              {loading && <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />}
            </span>
          </h2>
          {renderFragment}
        </div>
      </div>
    </div>
  )
}
