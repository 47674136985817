import { PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { SaveChanges } from 'components/SaveChanges'
import { monthsInYear } from 'config'
import { InputType } from 'config/input.type.constants'
import { DisbursementStartPurMap, PrepaidItemDescMap } from 'config/loan.bytepro.constants'
import { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { gethud1PageData, posthud1Page1Data } from 'services'
import { svgLoading } from 'stories/assets'
import { Button, PlainTable } from 'stories/components'
import { OverrideCaclModal } from 'stories/components/OverrideCalcModal/OverrideCalcModal'
import { PrepaidItemsDetailsModal } from 'stories/components/PrepaidItemDetailsModal/PrepaidItemDetailsModal'
import { formatDate, getPrice1or2decimal, getPrice2decimal, openAuditLog, removeComma } from 'utils'
import { RenderInput } from 'utils/RenderInput'

import { HUD1InputType } from '../HUD1Page1/constant'

const tableHeader = ['Month', 'Payments to Escrow', 'Payments from Escrow', 'Description', 'Account Balance']

export function AggregateEscrow() {
  const [action, setAction] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [changed, setChanged] = useState(false)
  const [inputs, setInputs] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [orgData, setOrgData] = useState<any>({})
  const [IDs, setIDs] = useState<Array<string>>([])
  const [ovData, setOVData] = useState<any>({})
  const [PrepaidItems, setPrepaidItems] = useState<Array<Record<string, string>>>([])
  const [prepaidItemsDetailData, setPrepaidItemsDetailData] = useState<any>({})
  const [trialBalanceData, setTrialBalanceData] = useState<Array<Array<any>>>([])
  const [aggregateAdjData, setAggregateAdjData] = useState<Array<Array<any>>>([])
  const [totalCushion, setTotalCushion] = useState<number>(0)
  const [minBalance, setMinBalance] = useState<number>(0)

  const initData = async () => {
    setAction('')
    setIsLoading(true)
    const res = await gethud1PageData('escrow')
    if (res.success) {
      setInputs(res.inputs)
      setData(res.data)
      setPrepaidItems(res.PrepaidItems)
      setOrgData(res.data)
      setIDs(res.IDs)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    const firstPaymentMonth = Number(formatDate(data['Loan.FirstPaymentDate'], 'M'))
    const firstPaymentYear = Number(formatDate(data['Loan.FirstPaymentDate'], 'YYYY'))
    const closingDateMonth = Number(formatDate(data['Status.SchedClosingDate'], 'M'))
    const closingDateYear = Number(formatDate(data['Status.SchedClosingDate'], 'YYYY'))
    const tempTrialBalance = [['', '', '', 'Initial Deposit', 0]]
    let paymentToEscrow: number = 0
    let paymentFromEscrows: Array<number> = []
    let descriptions: Array<string> = []
    for (let i = 0; i < 12; i++) {
      paymentFromEscrows.push(0)
      descriptions.push('')
    }

    let totalCush = 0
    for (let i = 0; i < 7; i++) {
      const disbursementSched = data[`PrepaidItems.${i}.DisbursementSched`]
      const payment = data[`PrepaidItems.${i}.Payment`]
      const disbursementStartPur = data[`PrepaidItems.${i}.DisbursementStartPur`]
      const disbursementStartYear = data[`PrepaidItems.${i}.DisbursementStartYear`]
      const disbursementPeriods = data[`PrepaidItems.${i}.DisbursementPeriods`]
      const disbursementList = data[`PrepaidItems.${i}.DisbursementList`]?.split('|')
      const cushion = data[`PrepaidItems.${i}.CushionOV`] || data[`PrepaidItems.${i}._Cushion`] || 0
      if (disbursementSched > 1) totalCush += cushion * payment

      if (disbursementSched > 1) paymentToEscrow += payment
      if (disbursementSched == 2) {
        for (let j = 0; j < 12; j++) {
          paymentFromEscrows[j] += payment
          if (descriptions[j]) descriptions[j] += ', '
          descriptions[j] += PrepaidItemDescMap[i]
        }
      } else if ([3, 4, 5].includes(Number(disbursementSched))) {
        if (disbursementStartPur == 0) continue
        let startMonth, startYear
        if (disbursementStartPur > 0 && disbursementStartPur < 13) {
          startMonth = disbursementStartPur
          startYear = disbursementStartYear
        } else {
          startMonth = closingDateMonth
          startYear = closingDateYear
        }
        let monthsInPeriod = 12
        if (disbursementSched == 3) monthsInPeriod = 3
        else if (disbursementSched == 4) monthsInPeriod = 6

        for (let j = (startMonth - firstPaymentMonth + 12) % 12; j < 12; j += monthsInPeriod) {
          if (!startYear || firstPaymentYear + (j + firstPaymentMonth > 12 ? 1 : 0) >= startYear) {
            paymentFromEscrows[j] += payment * monthsInPeriod
            if (descriptions[j]) descriptions[j] += ', '
            descriptions[j] += PrepaidItemDescMap[i]
          }
        }
      } else if (disbursementSched == 6) {
        for (let j = 0; j < 12; j++) {
          if (disbursementPeriods[j] && disbursementPeriods[j] != '.') {
            const index = (j + 1 - firstPaymentMonth + 12) % 12
            paymentFromEscrows[index] += payment * (disbursementPeriods[j].charCodeAt(0) - 'A'.charCodeAt(0) + 1)
            if (descriptions[index]) descriptions[index] += ', '
            descriptions[index] += PrepaidItemDescMap[i]
          }
        }
      } else if (disbursementSched == 7) {
        disbursementList?.map((v: any) => {
          const [date, amount] = v.split('~')
          const month = new Date(date).getMonth() + 1
          let year = new Date(date).getFullYear()
          if (month < firstPaymentMonth) year--
          if (year == firstPaymentYear) {
            const index = (month - firstPaymentMonth + 12) % 12
            paymentFromEscrows[index] += parseFloat(amount)
            if (descriptions[index]) descriptions[index] += ', '
            descriptions[index] += PrepaidItemDescMap[i]
          }
        })
      }
    }
    let minBal = 1000000000
    const formTalbeData = (tempTrialBalance: Array<Array<any>>) => {
      for (let i = 0; i < 12; i++) {
        let month = (firstPaymentMonth + i) % 12
        if (!month) month = 12
        const balance =
          Math.round(
            (Number(removeComma(tempTrialBalance[tempTrialBalance.length - 1][4])) +
              paymentToEscrow -
              paymentFromEscrows[i]) *
              100,
          ) / 100
        const item = [
          monthsInYear[month],
          getPrice2decimal(paymentToEscrow, false, true),
          getPrice2decimal(paymentFromEscrows[i], false, true),
          descriptions[i],
          getPrice2decimal(balance, false, true),
        ]
        tempTrialBalance.push(item)
        if (minBal > balance) minBal = balance
      }
      return tempTrialBalance
    }

    setTrialBalanceData(formTalbeData(tempTrialBalance))

    setMinBalance(minBal)
    const tempAggregaeAdj = [['', '', '', 'Initial Deposit', getPrice2decimal(totalCush - minBal, false, true)]]
    setAggregateAdjData(formTalbeData(tempAggregaeAdj))
    setTotalCushion(totalCush)
  }, [data])

  const onChange = (key: string, value: any, type?: string, orgData?: Record<string, any>) => {
    const hasOrgData = !!orgData
    if (!orgData) orgData = data
    let temp = cloneDeep(orgData!)

    const params = key.split('.')
    if (params.length > 2 && params[2] == 'CushionOV') value = value.split('.')[0]

    if (type === 'number') temp[key] = getPrice1or2decimal(value).replace(',', '')
    else temp[key] = value

    if (!hasOrgData) {
      setData(temp)
      if (!changed) setChanged(true)
    } else return temp
  }

  const showHistory = async (keys: Array<string>) => {
    const options = {
      table: 'HUD1',
      field: keys.join(', '),
      keys: {
        field: keys,
      },
      renderValue: (data: any) => <span dangerouslySetInnerHTML={{ __html: data }} />,
    }
    keys = keys.filter((key) => key)
    openAuditLog(options)
  }

  const onSaveChanges = async () => {
    setChanged(false)
    let json: any = {}
    const ppTemp = cloneDeep(PrepaidItems)

    Object.keys(data).map((key) => {
      if (JSON.stringify(data[key]) != JSON.stringify(orgData[key])) json[key] = data[key]
      const params = key.split('.')
      if (params[0] === 'PrepaidItems') ppTemp[+params[1]][params[2]] = data[key]
    })
    if (Object.keys(json).length > 0) {
      json.IDs = IDs
      json.PrepaidItems = PrepaidItems
      setAction('saveChanges')
      await posthud1Page1Data('page1', json)
      setAction('')
      setOrgData(data)
      setPrepaidItems(ppTemp)
    }
  }

  const closeOVModal = (save: false, data: any) => {
    if (save) onChange(ovData.overrideKey, data.ovValue, 'number')
    setOVData({})
  }

  const closeCostingDetailModal = (save: false, detailData: any) => {
    if (save) {
      let newData = cloneDeep(data)
      Object.keys(detailData).map((v) => {
        const { key, value } = detailData[v]
        if (data[key] !== value && Object.keys(data).includes(key)) {
          newData = onChange(key, value, '', newData)
        }
      })
      setData(newData)
      setChanged(true)
    }
    setPrepaidItemsDetailData({})
  }

  const renderSectionHeader = (title: string) => {
    const sectionInput: InputType = {
      inputType: 'section',
      title: title,
    }
    return <RenderInput Key={`title-${title}`} input={sectionInput} onChange={onChange} />
  }

  const renderHUD1Input = (input: any) => {
    const { type, title, titleBold, valueKey, overrideKey, canOverride, calculatedValue } = input
    const VALUE = getPrice1or2decimal(data[valueKey])
    const OVVALUE = getPrice1or2decimal(data[overrideKey])
    if (type === HUD1InputType.titleCalculatedValue) {
      // title + calculated value
      return (
        <div className="flex flex-wrap justify-end items-center gap-x-4 gap-y-1">
          <div className="flex-1 flex justify-between items-center">
            <div className={`flex gap-2 ${titleBold ? 'font-semibold' : ''}`}>
              <div className={`cursor-pointer hover:underline`} onClick={() => showHistory([valueKey, overrideKey])}>
                {title}
              </div>
            </div>
            {canOverride && (
              <span
                className="cursor-pointer hover:text-shade-blue"
                onClick={() =>
                  setOVData({
                    title,
                    overrideKey,
                    calcValue: calculatedValue || data[valueKey],
                    ovValue: data[overrideKey] || '',
                  })
                }
              >
                <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
              </span>
            )}
          </div>
          <div className="">
            <input
              disabled={true}
              className={`px-2 text-right border cursor-not-allowed w-[120px] ${OVVALUE ? 'bg-red-100' : ''}`}
              value={OVVALUE || VALUE}
            />
          </div>
        </div>
      )
    }
  }

  return (
    <div className="HUD1Page2-container relative">
      <LayoutLoading show={action !== '' || isLoading} />
      <Prompt
        when={changed}
        message={`You've made some changes on HUD1 Page2!\nAre you sure want to leave without Saving?`}
      />
      <h2 className="text-2xl font-bold flex items-center mb-3">
        Aggregate Escrow
        {isLoading && (
          <span className="ml-3">
            <img src={svgLoading} className="inline w-6 h-6 text-white animate-spin" />
          </span>
        )}
      </h2>
      <div className="grid gap-4 md:grid-cols-8 sm:grid-cols-8 grid-cols-1">
        <div className="md:col-span-8 sm:col-span-8 my-4">{renderSectionHeader('General')}</div>
        {(inputs['general'] || []).map((input: any) => {
          const { valueKey } = input
          input.value = data[valueKey]
          input.history = true

          return (
            <div className={`input flex-1 md:col-span-${input.span || 2} sm:col-span-4`} key={valueKey}>
              <RenderInput
                input={input}
                Key={valueKey}
                onChange={onChange}
                showHistory={() => showHistory([valueKey])}
              />
            </div>
          )
        })}
        <div className="md:col-span-8 sm:col-span-8  my-4">
          {renderSectionHeader('Disbursement Schedule and Cushion')}
        </div>
      </div>
      {(inputs['disbSched'] || []).map((input: any, index: number) => {
        const { valueKey } = input
        const prefix = `PrepaidItems.${index}.`
        input.value = data[valueKey]
        input.history = true
        if (index != 1)
          input.additionalElements = (
            <span
              className="cursor-pointer hover:text-shade-blue"
              onClick={() =>
                setPrepaidItemsDetailData({
                  title: [3, 7].includes(data[`${prefix}PrepaidItemType`]) ? data[`${prefix}NameOV`] : input.title,
                  payment: { value: data[`${prefix}Payment`], key: `${prefix}Payment` },
                  periodicPayment: {
                    value: data[`${prefix}PeriodicPaymentAmount`],
                    key: `${prefix}PeriodicPaymentAmount`,
                  },
                  disbSched: { value: data[`${prefix}DisbursementSched`], key: `${prefix}DisbursementSched` },
                  starting: { value: data[`${prefix}DisbursementStartPur`], key: `${prefix}DisbursementStartPur` },
                  cushion: { value: data[`${prefix}_Cushion`], key: `${prefix}_Cushion` },
                  cushionOV: { value: data[`${prefix}CushionOV`], key: `${prefix}CushionOV` },
                  monthsInAdvance: { value: data[`${prefix}MonthsInAdvance`], key: `${prefix}MonthsInAdvance` },
                  premiumOV: { value: data[`${prefix}PremiumOV`], key: `${prefix}PremiumOV` },
                  premiumPBS: { value: data[`${prefix}PremiumPBSDesired`], key: `${prefix}PremiumPBSDesired` },
                  premiumPOC: { value: data[`${prefix}PremiumPOC`], key: `${prefix}PremiumPOC` },
                  netFromWire: { value: data[`${prefix}PremiumNetFromWire`], key: `${prefix}PremiumNetFromWire` },
                  paidTo: { value: data[`${prefix}PremiumPaidToType`], key: `${prefix}PremiumPaidToType` },
                  premPointsAndFeesOV: {
                    value: data[`${prefix}PremiumPointsAndFeesAmountOV`],
                    key: `${prefix}PremiumPointsAndFeesAmountOV`,
                  },
                  monthsInReserve: { value: data[`${prefix}MonthsInReserve`], key: `${prefix}MonthsInReserve` },
                  reserveOV: { value: data[`${prefix}ReservesOV`], key: `${prefix}ReservesOV` },
                  reservePBS: { value: data[`${prefix}ReservesPBSDesired`], key: `${prefix}ReservesPBSDesired` },
                  reservesPOC: { value: data[`${prefix}ReservesPOC`], key: `${prefix}ReservesPOC` },
                  resPointsAndFeesOV: {
                    value: data[`${prefix}ReservesPointsAndFeesAmountOV`],
                    key: `${prefix}ReservesPointsAndFeesAmountOV`,
                  },
                  atrNotes: { value: data[`${prefix}QMATRNotes`], key: `${prefix}QMATRNotes` },
                  premiumPaidByOther: {
                    value: data[`${prefix}PremiumPaidByOtherType`],
                    key: `${prefix}PremiumPaidByOtherType`,
                  },
                  reservePaidByOther: {
                    value: data[`${prefix}ReservesPaidByOtherType`],
                    key: `${prefix}ReservesPaidByOtherType`,
                  },
                  prepaidItemType: {
                    value: data[`${prefix}MISMOPrepaidItemTypeOV`],
                    key: `${prefix}MISMOPrepaidItemTypeOV`,
                  },
                  disbStartYear: {
                    value: data[`${prefix}DisbursementStartYear`],
                    key: `${prefix}DisbursementStartYear`,
                  },
                  disbPeriods: { value: data[`${prefix}DisbursementPeriods`], key: `${prefix}DisbursementPeriods` },
                  disbList: { value: data[`${prefix}DisbursementList`], key: `${prefix}DisbursementList` },
                })
              }
            >
              <PencilSquareIcon className="w-4 h-4"></PencilSquareIcon>
            </span>
          )

        const cushion = {
          title: 'Cushion',
          type: HUD1InputType.titleCalculatedValue,
          valueKey: `${prefix}_Cushion`,
          overrideKey: `${prefix}CushionOV`,
          canOverride: true,
        }

        const starting: any = {
          inputType: 'select',
          title: 'Starting',
          options: DisbursementStartPurMap,
          valueKey: `${prefix}DisbursementStartPur`,
          value: data[`${prefix}DisbursementStartPur`],
          history: true,
        }

        const disbStartYear: any = {
          inputType: 'text',
          title: 'Year',
          contentType: 'number',
          valueKey: `${prefix}DisbursementStartYear`,
          value: data[`${prefix}DisbursementStartYear`],
          history: true,
        }

        const showStarting = !['0', '1', '2', '6', '7', '', 0, 1, 2, 6, 7].includes(data[`${prefix}DisbursementSched`])
        const showDisbStartYear = !(
          ['0', '1', '2', '6', '7', '', 0, 1, 2, 6, 7].includes(data[`${prefix}DisbursementSched`]) ||
          ['0', '13', '', 0, 13].includes(data[`${prefix}DisbursementStartPur`])
        )
        const showCushion = !['0', '1', '', 0, 1].includes(data[`${prefix}DisbursementSched`])
        return (
          <div
            className={`grid gap-4 md:grid-cols-8 sm:grid-cols-8 grid-cols-1 my-4 p-2 ${
              index % 2 == 0 && 'bg-slate-100'
            }`}
          >
            <div className={`input flex-1 md:col-span-3 sm:col-span-3`} key={valueKey}>
              <RenderInput
                input={input}
                Key={valueKey}
                onChange={onChange}
                showHistory={() => showHistory([valueKey])}
              />
            </div>
            <div
              className={`input flex-1 md:col-span-2 sm:col-span-2 sm:block ${!showStarting && 'hidden'}`}
              key={starting.valueKey}
            >
              {showStarting && (
                <RenderInput
                  input={starting}
                  Key={starting.valueKey}
                  onChange={onChange}
                  showHistory={() => showHistory([valueKey])}
                />
              )}
            </div>
            <div
              className={`input flex-1 md:col-span-1 sm:col-span-1 sm:block ${!showDisbStartYear && 'hidden'}`}
              key={disbStartYear.valueKey}
            >
              {showDisbStartYear && (
                <RenderInput
                  input={disbStartYear}
                  Key={disbStartYear.valueKey}
                  onChange={onChange}
                  showHistory={() => showHistory([valueKey])}
                />
              )}
            </div>
            <div
              className={`input flex-1 md:col-span-2 sm:col-span-2 sm:block ${!showCushion && 'hidden'}`}
              key={cushion.valueKey}
            >
              {showCushion && renderHUD1Input(cushion)}
            </div>
          </div>
        )
      })}
      <div className="my-4">{renderSectionHeader('Trial Balance')}</div>
      <div className="mt-4">
        <PlainTable header={tableHeader} data={trialBalanceData} />
      </div>
      <div className="my-4">{renderSectionHeader('Initial Escrow Account Disclosure Statement')}</div>
      <div className="mt-4">
        <PlainTable header={tableHeader} data={aggregateAdjData} />
      </div>
      {inputs['adjustmentExplain']?.map((input: any) => {
        let cushionContent: string = 'Cushion calculation:'
        let sum: number = 0
        for (let i = 0; i < 7; i++) {
          const prefix = `PrepaidItems.${i}.`
          if (['0', '1', '', 0, 1].includes(data[`${prefix}DisbursementSched`])) continue
          const cushion = Number(data[`${prefix}CushionOV`] || data[`${prefix}_Cushion`])
          const payment = Number(data[`${prefix}Payment`])
          const result = cushion * payment
          sum += result
          cushionContent += `\n\t${inputs['disbSched'][i]?.title} cushion: ${cushion} months * ${getPrice2decimal(
            payment,
            false,
            true,
          )}/month = ${getPrice2decimal(result, false, true)}`
        }
        cushionContent += `\n\tTotal Cushion: ${getPrice2decimal(sum, false, true)}\n\n`
        cushionContent += `Initial account balance calculation:\n\tTotal Cushion: ${getPrice2decimal(
          totalCushion,
          false,
          true,
        )}\n\tMinus minimum balance from trial balance: ${getPrice2decimal(
          minBalance,
          false,
          true,
        )}\n\t= Initial account balance: ${getPrice2decimal(totalCushion - minBalance, false, true)}\n\n`
        cushionContent += [
          `Aggregate account adjustment calculation:`,
          `Initial account balance: ${getPrice2decimal(totalCushion - minBalance, false, true)}`,
        ].join('\n\t')
        sum = totalCushion - minBalance
        for (let i = 0; i < 7; i++) {
          const prefix = `PrepaidItems.${i}.`
          if (['0', '1', '', 0, 1].includes(data[`${prefix}DisbursementSched`])) continue
          const monthInReserve = Number(data[`${prefix}MonthsInReserve`])
          const payment = Number(data[`${prefix}Payment`])
          const result = monthInReserve * payment
          sum -= result
          cushionContent += `\n\tMinus ${
            inputs['disbSched'][i]?.title
          } cushion: ${monthInReserve} months * ${getPrice2decimal(payment, false, true)}/month = ${getPrice2decimal(
            result,
            false,
            true,
          )}`
        }
        cushionContent += `\n\t= Aggregate account adjustment: ${getPrice2decimal(sum, false, true)}`
        input.value = cushionContent
        return <RenderInput input={input} Key="adjustmentExplain" onChange={onChange} />
      })}
      {Object.keys(ovData).length > 0 && (
        <OverrideCaclModal
          key={ovData.overrideKey}
          title={ovData.title}
          calcValue={ovData.calcValue}
          ovValue={ovData.ovValue}
          onClose={closeOVModal}
        />
      )}
      {Object.keys(prepaidItemsDetailData).length > 0 && (
        <PrepaidItemsDetailsModal
          prepaidItems={prepaidItemsDetailData}
          onClose={closeCostingDetailModal}
          onSubmit={closeCostingDetailModal}
        />
      )}
      <div className="flex justify-center mt-4">
        <Button disabled={!changed} onClick={onSaveChanges} loading={action === 'saveChanges'}>
          Save Changes
        </Button>
      </div>
      <SaveChanges show={changed} label="Save Changes" onSave={onSaveChanges} />
    </div>
  )
}
