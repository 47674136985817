import { ArrowDownTrayIcon, PencilSquareIcon } from '@heroicons/react/24/outline'
import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { Overview } from 'components/Overview'
import { loanStatusConstants, SUCCESSFULLY_CREATED } from 'config'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { addClosingCalendar, downloadFile, getAdminConfig, getClosingCalendar, signClosingCalendarPDF } from 'services'
import { Button, PlainTable } from 'stories/components'
import { InputConvert, InputValidate, thousandSeperatorWithPrefix } from 'utils'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

import { ClosingCalendarInfo } from './ClosingCalendarInfo'
import { defaultInputs } from './constants'
import { FeeDialog } from './FeeDialog'
import { PartiesEmailToDialog } from './PartiesToEmailDialog'
import { IClosingCalendarData } from './types'

export * from './SignClosingCalendar'

export function ClosingCalendar() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IClosingCalendarData[]>([])
  const [inputs, setInputs] = useState(defaultInputs())
  const [feed, setFeed] = useState<any>({})
  const [loanStatus, setLoanStatus] = useState('')
  const [description, setDescription] = useState('')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isBrokerModalOpen, setIsBrokerModalOpen] = useState<boolean>(false)
  const [feeData, setFeeData] = useState<any>([])

  const loanNumber = setLoanNumber()

  const { loan, loanDetail } = useSelector((state: any) => {
    return {
      loan: state.loan,
      loanDetail: state.loanDetail,
    }
  })

  const loanInfo = {
    brokerName: loanDetail.parties?.broker?.name,
    borrowerName: loanDetail.borrowerName,
    subjectProperty: loanDetail.propertyAddress,
    loanTerm: loan.loanTerm,
    loanProgram: loanDetail.rateData?.program,
    loanAmount: loan.proposedLoanAmount,
    rate: loanDetail.rateData?.rate,
  }

  const defaultItems = [
    { title: 'Broker Fee', amount: 0 },
    { title: 'Attorney Fee', amount: 0 },
    { title: 'Processing Fee', amount: 0 },
  ]

  useEffect(() => {
    setLoading(true)
    setLoanNumber()
    getClosingCalendar().then(({ data = [], loanStatus, feed }) => {
      setData(data)
      setLoanStatus(loanStatus)
      setFeed(feed)
      resetInputs(feed)
      setFeeData(defaultItems)
      getAdminConfig('textConfig', { key: 'closingCalendarDesc' }).then((value) => {
        setDescription(value.replace(/\n/g, '<br />') || '')
        setLoading(false)
      })
    })
  }, [])

  const resetInputs = (values: Record<string, any> = {}) => {
    const newInputs = cloneDeep(inputs)
    Object.keys(newInputs).forEach((key) => {
      newInputs[key].value = values[key] || ''
      newInputs[key].error = ''
    })
    setInputs(newInputs)
  }

  const onChange = (key: string, value: string) => {
    let newInputs = cloneDeep(inputs)
    if (key != 'fees') value = InputConvert(newInputs[key], value)
    newInputs[key].value = value
    newInputs[key].error = InputValidate(newInputs[key])
    setInputs(newInputs)
  }

  const onSchedule = () => {
    const newInputs = cloneDeep(inputs)
    const values: Record<string, any> = {}
    let hasError = false
    Object.entries(inputs).forEach(([key, input]) => {
      const error = InputValidate(input)
      newInputs[key].error = error

      if (error) hasError = true

      if (key === 'titleAgentPhoneNumber') values[key] = InputConvert(input, input.value)
      else if (key === 'fees') values[key] = feeData
      else values[key] = input.value
    })

    setInputs(newInputs)
    if (hasError) return

    const newData = cloneDeep(data)
    newData.push(values as IClosingCalendarData)
    setData(newData)
    setLoading(true)
    addClosingCalendar(values)
      .then(() => {
        toast(SUCCESSFULLY_CREATED, { type: 'success' })
        resetInputs(feed)
        setFeeData(defaultItems)
      })
      .finally(() => setLoading(false))
  }

  const onClose = () => {
    setIsModalOpen(false)
    setIsBrokerModalOpen(false)
  }

  const canRequest = useMemo(() => {
    return loanStatusConstants.closeStatus.indexOf(loanStatus) === -1 ? false : true
  }, [loanStatus])

  const onFeeSubmit = (value: any) => {
    setFeeData(value)

    // onChange('fees', val)
  }

  const downlaod = async () => {
    setLoading(true)

    const pdfData = await signClosingCalendarPDF({ sign: false })
    downloadFile(`Closing Calendar ${loanNumber}(${moment().tz('America/New_York').format('YYYY-MM-DD')}).pdf`, pdfData)

    setLoading(false)
  }

  return (
    <div className="ClosingCalander-container px-2 py-6">
      <Overview title="Closing Calendar" />
      <div className="max-w-screen-2xl m-auto">
        <div className="relative bg-white shadow1 rounded mb-4 p-4">
          <LayoutLoading show={loading} />
          {!canRequest && loanStatus.length > 0 && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4 text-[15px]"
              role="alert"
            >
              Loan Status must be after <span className="font-bold">Clear to Close</span> to request Closing!
              <br />
              Current Loan Status: <span className="ml-2 font-bold">{loanStatusConstants.status[loanStatus]}</span>
            </div>
          )}
          <div className="p-2 flex gap-2 items-center">
            <Button onClick={() => setIsModalOpen(true)}>E-sign Verification</Button>
            <Button onClick={() => downlaod()} color="blue" link>
              <p className="flex items-center gap-2 -mt-2">
                Download unsigned Closing Calendar
                <ArrowDownTrayIcon className="w-4 h-4"></ArrowDownTrayIcon>
              </p>
            </Button>
          </div>

          <ClosingCalendarInfo loanInfo={loanInfo} data={data} description={description} />

          <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
            {Object.keys(inputs).map((key, index) => {
              let input = inputs[key]

              return (
                <div className={`input md:col-span-${input.span || 1}`} key={index}>
                  <RenderInput input={input} Key={key} onChange={onChange} />
                </div>
              )
            })}
          </div>
          <div className="grid gap-4 md:grid-cols-2 grid-cols-1 mb-3">
            <div className="relative overflow-auto">
              <span className="flex justify-between">
                Fees <PencilSquareIcon className="w-4 h-4 cursor-pointer" onClick={() => setIsBrokerModalOpen(true)} />
              </span>
              <PlainTable
                header={['No', 'Title', 'Amount']}
                tdClass="px-6 py-4 whitespace-nowrap capitalize"
                data={feeData.map((item: any, index: number) => [
                  index + 1,
                  item.title,
                  thousandSeperatorWithPrefix(item.amount),
                ])}
              />
            </div>
          </div>
          <div className="block text-center">
            <Button onClick={onSchedule} className="px-10" loading={loading} disabled={!canRequest}>
              Schedule Closing Request
            </Button>
          </div>
        </div>
      </div>
      {isModalOpen && <PartiesEmailToDialog loanNumber={loanNumber} onClose={onClose} />}
      {isBrokerModalOpen && (
        <>
          <p>dialog</p>
          <FeeDialog modalTitle="Update Fees" onClose={onClose} onSubmit={onFeeSubmit} data={feeData} />
        </>
      )}
    </div>
  )
}
